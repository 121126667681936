import React, { useState, useEffect } from 'react';

const Typewritertwo = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayText(text.substring(0, currentIndex + 1));
      setCurrentIndex(currentIndex + 1);
    }, 100); // Adjust typing speed here

    // Clear timeout if component unmounts or text fully displayed
    return () => {
      clearTimeout(timer);
    };
  }, [text, currentIndex]);

  return <p>{displayText}</p>;
};

export default Typewritertwo;
