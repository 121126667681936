import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Telegram, Twitter } from "@mui/icons-material";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import SendIcon from "@mui/icons-material/Send";
import ApiRepository from "../../API/ApiRepository";
import "./index.scss";
import xarioLogo from "../../assets/logo-sbl.svg";
import userIcon from "../../assets/xarioLogo.png";
import addbtn from "../../assets/add-30.png";
import msgIcon from "../../assets/message.svg";
import discourd from "../../assets/discord-icon.png";
import CopyAndShare from "../CopyAndShare/Copy";
import Typewriter from "../Animaction/Typewriter";
import Typewritertwo from "../Animaction/Animaction-typing";
// import Typical from "react-typical";

const XarioAI = () => {
  const msgEnd = useRef(null);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    {
      text: "Hi I am Xario, Your crypto wingman. Please enter the coin code for the crypto you wish to find out for eg: BTC for bitcoin.",
      isBot: true,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    msgEnd.current.scrollIntoView();
  }, [messages]);

  const handleSearch = async () => {
    setIsLoading(true); // Set loading to true when request starts
    try {
      const text = input.toUpperCase();
      setInput("");
      setMessages([
        ...messages,
        {
          text,
          isBot: false,
        },
      ]);
      const Response = await ApiRepository.fetchChatMessage(text);
      console.log(Response)

      const Data = {
        name: Response.name || "",
        symbol: Response.symbol || "",
        currentPrice: Response.current_price_usd
          ? Response.current_price_usd.toFixed(2)
          : "",
        analysis: Response.analysis || "",
        message: Response.message || "",
        response : Response.response || "",
      };
  
      

    const formattedResponse = `
      ${Data.name} ${Data.symbol} ${Data.currentPrice}
      ${Data.analysis} ${Data.message} ${Data.response}
        `;

      setMessages([
        ...messages,
        {
          text,
          isBot: false,
        },
        {
          text: formattedResponse,
          isBot: true,
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set loading to false when request completes
    }
  };
  // const handleSearch = async () => {
  //   setIsLoading(true); // Set loading to true when request starts
  //   try {
  //     const text = input.toUpperCase();
  //     setInput("");
  //     setMessages([
  //       ...messages,
  //       {
  //         text,
  //         isBot: false,
  //       },
  //     ]);
  //     const response = await ApiRepository.fetchCryptoBySymbol(text);
  //     const marketcap = await ApiRepository.fetchCryptoByName(text);
  //     const detail = await ApiRepository.fetchInfoByName(text);

  //     const { name, symbol, current_price_usd, analysis } = response;
  //     const { market_cap } = marketcap;
  //     const { description } = detail;

  //     setMessages([
  //       ...messages,
  //       {
  //         text,
  //         isBot: false,
  //       },
  //       {
  //         text: [
  //           `Name: ${name}.`,
  //           `Symbol: ${symbol}.`,
  //           `Price: ${current_price_usd}.`,
  //           `Market-Cap: ${market_cap}.`,
  //           `Detail: ${description}`,
  //           `Analysis: ${analysis}`,
  //         ].join("\n"),
  //         isBot: true,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setIsLoading(false); // Set loading to false when request completes
  //   }
  // };

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      await handleSearch();
    }
  };


 const handleQuery = async (e) => {
    try {
      const text = e.target.value;

      setInput("");
      setMessages([
        ...messages,
        {
          text,
          isBot: false,
        },
      ]);

    const Response = await ApiRepository.fetchChatMessage(text);

    const Data = {
      name: Response.name || "",
      symbol: Response.symbol || "",
      currentPrice: Response.current_price_usd
        ? Response.current_price_usd.toFixed(2)
        : "",
      analysis: Response.analysis || "",
      message: Response.message || "",
      response : Response.response || "",
    };

    

  const formattedResponse = `
    ${Data.name} ${Data.symbol} ${Data.currentPrice}
    ${Data.analysis} ${Data.message} ${Data.response}
      `;


    setMessages([
      ...messages,
      {
        text,
        isBot: false,
      },
      {
        text: formattedResponse,
        isBot: true,
      },
    ]);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false); // Set loading to false when request completes
  }
};
  // const handleQuery = async (e) => {
  //   try {
  //     const text = e.target.value;

  //     setInput("");
  //     setMessages([
  //       ...messages,
  //       {
  //         text,
  //         isBot: false,
  //       },
  //     ]);

  //     const response = await ApiRepository.fetchCryptoBySymbol(text);
  //     const marketcap = await ApiRepository.fetchCryptoByName(text);
  //     const detail = await ApiRepository.fetchInfoByName(text);

  //     const { name, symbol, current_price_usd, analysis } = response;
  //     const { market_cap } = marketcap;
  //     const { description } = detail;

  //     setMessages([
  //       ...messages,
  //       {
  //         text,
  //         isBot: false,
  //       },
  //       {
  //         text: [
  //           `Name: ${name}.`,
  //           `Symbol: ${symbol}.`,
  //           `Price: ${current_price_usd}.`,
  //           `Market-Cap: ${market_cap}.`,
  //           `Detail: ${description}`,
  //           `Analysis: ${analysis}`,
  //         ].join("\n"),
  //         isBot: true,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  return (
    <>
      <div
        className={`App container-fluid row ${
          toggleMenu ? "sidebar-open" : "sidebar-closed"
        }`}
      >
        <div
          className={` col-1  ${
            toggleMenu ? "open  sidebar col-lg-2 col-12" : ""
          }`}
        >
          {toggleMenu ? (
            <RiCloseLine
              color="#fff"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#fff"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <>
              <div className="upperSide p-4 row">
                <Link
                  to={"/"}
                  className="upperSideTop d-flex justify-content-center align-items-center mb-4 text-decoration-none text-white"
                >
                  <Image
                    src={xarioLogo}
                    width={58}
                    alt="Logo"
                    className=" me-3 d-none d-md-block"
                  />
                  <Image
                    src={xarioLogo}
                    width={72}
                    alt="Logo"
                    className=" me-3 d-block d-md-none"
                  />
                </Link>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="midBtn col-12 text-white p-1 fs-4"
                >
                  <Image src={addbtn} alt="New Chat" className="addBtn me-2" />
                  New Chat
                </Button>
              </div>
              <div className="MidSide p-4 row">
                <Button
                  onClick={handleQuery}
                  value={"BTC"}
                  className="query p-1 text-white  p-md-3 col-12  mb-1 "
                >
                  <Image src={msgIcon} alt="Query" className="addBtn me-4" />
                  Bitcoin
                </Button>
                <Button
                  onClick={handleQuery}
                  value={"ETH"}
                  className="query p-1 text-white  p-md-3 col-12 mb-1"
                >
                  <Image src={msgIcon} alt="Query" className="addBtn me-4" />
                  Ethereum
                </Button>
                <Button
                  // variant="outlined"
                  onClick={handleQuery}
                  value={"SOL"}
                  className="query p-1 text-white  p-md-3 col-12 mb-1"
                >
                  <Image src={msgIcon} alt="Query" className="addBtn me-4" />
                  Solana
                </Button>
                <Button
                  onClick={handleQuery}
                  value={"AVAX"}
                  className="query p-1 text-white  p-md-3 col-12 mb-1"
                >
                  <Image src={msgIcon} alt="Query" className="addBtn me-4" />
                  Avalanche
                </Button>
              </div>
              <div className="secondLastSide p-4">
                <Button className="midBtn col-12 text-white p-2 fs-4">
                  Login
                </Button>
              </div>
              <div className="lowerSide p-3 ">
                <h5 className="listitems m-0">Connect with us on</h5>
                <Link
                  to={"https://twitter.com/XarioAI"}
                  target="_blank"
                  className="listitems"
                >
                  <Twitter />
                </Link>
                <Link
                  to={"https://t.me/xarioai"}
                  target="_blank"
                  className="listitems"
                >
                  <Telegram />
                </Link>
                <Link
                  to={"https://discord.gg/Cf7X7kbdqF"}
                  target="_blank"
                  className="listitems"
                >
                  <Image
                    className=" text-white"
                    src={discourd}
                    alt="discourd Icon"
                    width={15}
                  />
                </Link>
              </div>
            </>
          )}
        </div>

        <div
          className={`row p-1 p-md-5 main col-12 col-md-12 col-lg-10 ${
            toggleMenu ? "col-lg-10" : "col-lg-11"
          }`}
        >
          <div className="chats overflow-auto col-12 col-lg-10">
            {messages.map((message, i) => (
              <div key={i} className={message.isBot ? "chat bot" : "chat"}>
                <Image
                  className="chatimg"
                  src={message.isBot ? userIcon : userIcon}
                  alt={message.isBot ? "App Icon" : "User Icon"}
                />

                <p className="txt">
                  {message.isBot ? (
                    <Typewriter text={message.text} />
                  ) : (
                    message.text
                  )}
                  {message.isBot ? (
                    <CopyAndShare textToCopy={message.text} />
                  ) : (
                    ""
                  )}
                  {message.isBot
                    ? ""
                    : isLoading && (
                        <Typewritertwo
                          text={`Please wait while Xario configures the data and provides you its
                predictive analysis...`}
                        />
                      )}
                </p>
                <div ref={msgEnd} />
              </div>
            ))}
          </div>
          <div className="chatFooter mt-2 col-12 col-lg-10">
            <div className="inp">
              <input
                type="text"
                placeholder="Enter a cryptocurrency and do a knowledge search."
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyDown={(e) => handleEnter(e)}
                className=" text-white"
              />
              <Button
                className="send position-static text-white"
                onClick={handleSearch}
                onKeyDown={handleEnter}
              >
                <SendIcon fontSize="large" />
              </Button>
            </div>
            <p className="gptnotic">
              Xario can make mistakes. Consider checking important information.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default XarioAI;
