import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import "./ChatNow.scss"

const TwoSideArrow = ({ PgLink, Tittle }) => {
  return (
    <>
      <Link to={PgLink} className="text-decoration-none col-12 col-md-6 mb-4 mb-lg-0  d-flex justify-content-center ">
        <Button
          variant="outlined"
          className="w-100 p-3 fs-4 text-white d-flex justify-content-around px-5 text-capitalize Button-border"
        >
          <EastIcon className=" fs-2" />
          <span className=" m-0">{Tittle}</span>
        </Button>
      </Link>
    </>
  );
};

export default TwoSideArrow;
