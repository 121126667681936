import Predicts from "../assets/Service/Predicts.png";
import Informs from "../assets/Service/Informs.png";
import Analyse from "../assets/Service/Analyse.png";
import Power from "../assets/Service/Power of LLMs.png";
import Market from "../assets/Service/Market Insight.png";
import Technical from "../assets/Service/Technical Analysis.png";

const Services = [
  {
    Id: "1",
    Sname: "Predict",
    Img: Predicts,
    Details:
      "Our cutting-edge 'predict' feature employs advanced algorithms and historical market data analysis to forecast the future price.",
  },
  {
    Id: "2",
    Sname: "Inform",
    Img: Informs,
    Details:
      "Xario AI is here to provide you with cutting-edge AI-powered insights & predictions, keeping you updated with latest crypto.",
  },
  {
    Id: "3",
    Sname: "Analyse",
    Img: Analyse,
    Details:
      "The 'analyze' feature leverages comprehensive technical analysis using cutting-edge AI models thus providiing the most efficient crypto data.",
  },
  {
    Id: "4",
    Sname: "Power of LLMs",
    Img: Power,
    Details:
      "Xario uses the most advanced large language models which makes it possible to analyze real-time on chain crypto data.",
  },
  {
    Id: "5",
    Sname: "Market Insight",
    Img: Market,
    Details:
      "Xario offer real-time overview of trends, allowing them to stay ahead of the game by identifying assests beforehand.",
  },
  {
    Id: "6",
    Sname: "Technical Analysis",
    Img: Technical,
    Details:
      "Professional technical and market analysis to help investors and crypto enthusiasts to decide on where to invest.",
  },
];

export default Services;

// const Services = [
//     {
//         Id: "1",
//         Sname: "The Watcher",
//         Img: "",
//         Details: "A Netflix Original Series",

//     },
//     {
//         Id: "2",
//         Sname: "The Ultimatum",
//         Img: "",
//         Details: "A Netflix Original Series",

//     },
//     {
//         Id: "3",
//         Sname: "Archive 81",
//         Img: "",
//         Details: "A Netflix Original Series",

//     },
//     {
//         Id: "4",
//         Sname: "After Life",
//         Img: "",
//         Details: "A Netflix Original Series",

//     },
//     {
//         Id: "5",
//         Sname: "Ozark",
//         Img: "",
//         Details: "A Netflix Original Series",

//     },
//     {
//         Id: "6",
//         Sname: "Inventing Anna",
//         Img: "",
//         Details: "A Netflix Original Series",

//     },
// ];

// export default Services;
