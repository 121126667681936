import { fetchData } from "./apiUtils";

class ApiRepository {
  baseUrl = "/chat/";

  // Fetch chat messages (using POST)
  async fetchChatMessage(message) {
    const data = { message };
    try {
      return await fetchData(this.baseUrl, "POST", data);
    } catch (error) {
      console.error("Failed to fetch chat message:", error);
      throw error;
    }
  }

}

export default new ApiRepository();




// import { fetchData } from "./apiUtils";
// import axios from "axios";
// class ApiRepository {
//   baseUrl = "https://xarioapi.onrender.com";

//   async fetchChatMessage(message) {
//     const url = `${this.baseUrl}/chat/`;
//     const data = { message};

//     try {
//       const response = await axios.post(url, data, {
//         headers: {
//           "Content-Type": "application/json",
//           accept: "application/json",
//         },
//       });
//       return response.data;
//     } catch (error) {
//       console.error("API error:", error);
//       throw error;
//     }
//   }
// }

// export default new ApiRepository();




// import { fetchData } from "./apiUtils";

// class ApiRepository {
//   baseUrl = "https://api.xario.ai";

//   async fetchCryptoBySymbol(symbol) {
//     const url = `${this.baseUrl}/crypto/${symbol}`;
//     return fetchData(url);
//   }

//   async fetchCryptoByName(name) {
//     const url = `${this.baseUrl}/info/${name}`;
//     return fetchData(url);
//   }

//   async fetchInfoByName(name) {
//     const url = `${this.baseUrl}/metadata/${name}`;
//     return fetchData(url);
//   }
// }

// export default new ApiRepository();
