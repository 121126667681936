import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";

const Popup = ({ isOpen, onAccept, onManages }) => {
  const [showPopup, setShowPopup] = useState(isOpen);

  useEffect(() => {
    // Check if the user has accepted cookies in local storage
    const hasAcceptedCookies = localStorage.getItem("acceptedCookies");

    // If accepted, close the popup
    if (hasAcceptedCookies === "true") {
      setShowPopup(false);
    } else {
      // If not accepted, show the popup
      setShowPopup(isOpen);
    }
  }, [isOpen]);

  const handleAccept = () => {
    // Set a cookie named 'acceptedCookie' with value 'true'
    Cookies.set("acceptedCookie", "true", { expires: 7 });

    // Store in local storage that the user has accepted cookies
    localStorage.setItem("acceptedCookies", "true");

    onAccept(); // Call the provided onAccept function
  };

  const handleClose = () => {
    setShowPopup(false);
    onManages();
  };

  return (
    <Modal
      show={showPopup}
      // show={isOpen}
      onHide={handleClose}
      // onHide={onManages}
      className=" mt-5 pt-5"
    >
      <Modal.Header closeButton style={{ background: "#646563" }}>
        <Modal.Title>👋 Welcome!</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#646563" }}>
        <h5 className=" p-2">Xario.ai - Your Ultimate Crypto Companion.</h5>
        <h5>
          Important reminders:
          <ul style={{ listStyle: "lower-alpha" }}>
            <li className=" p-2">
              ⚠️ Trade at your own risk. Exercise caution and do your research
              before making investment decisions.
            </li>
            <li className=" p-2">
              💰 Crypto markets are volatile. Prices can fluctuate rapidly, so
              stay informed and manage risks wisely.
            </li>
            <li className=" p-2">
              🚀 Xario.ai provides predictions and analysis to assist you.
              However, please note that these are based on historical data and
              trends, and future results may vary.
            </li>
          </ul>
        </h5>
        <h5 className=" p-2">
          We're here to empower you, but remember to make informed decisions and
          understand the risks involved in the crypto market.
        </h5>
      </Modal.Body>
      <Modal.Footer
        className=" justify-content-between"
        style={{ background: "#646563" }}
      >
        <h5 className=" w-100 p-2 d-flex justify-content-center">
          We use cookies to enhance the user experience.
        </h5>
        <Button variant="secondary" onClick={onManages}>
          Manage Cookies
        </Button>
        <Button variant="primary" onClick={handleAccept}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;


// import React from "react";
// import { Modal, Button } from "react-bootstrap";

// const Popup = ({ isOpen, onAccept, onManages }) => {

//   return (
//     <Modal show={isOpen} onHide={onManages} className=" mt-5 pt-5">
//       <Modal.Header closeButton style={{ background: "#646563" }}>
//         <Modal.Title>👋 Welcome!</Modal.Title>
//       </Modal.Header>
//       <Modal.Body style={{ background: "#646563" }}>
//         <h5 className=" p-2">Xario.ai - Your Ultimate Crypto Companion.</h5>
//         <h5>
//           Important reminders:
//           <ul style={{ listStyle: "lower-roman" }}>
//             <li className=" p-2">
//               ⚠️ Trade at your own risk. Exercise caution and do your research
//               before making investment decisions.
//             </li>
//             <li className=" p-2">
//               💰 Crypto markets are volatile. Prices can fluctuate rapidly, so
//               stay informed and manage risks wisely.
//             </li>
//             <li className=" p-2">
//               🚀 Xario.ai provides predictions and analysis to assist you.
//               However, please note that these are based on historical data and
//               trends, and future results may vary.
//             </li>
//           </ul>
//         </h5>
//         <h5 className=" p-2">
//           We're here to empower you, but remember to make informed decisions and
//           understand the risks involved in the crypto market.
//         </h5>
//       </Modal.Body>
//       <Modal.Footer
//         className=" justify-content-between"
//         style={{ background: "#646563" }}
//       >
//         <h5 className=" w-100 p-2 d-flex justify-content-center">
//           We use cookies to enhance the user experience.
//         </h5>
//         <Button variant="secondary" onClick={onManages}>
//           Manage Cookies
//         </Button>
//         <Button variant="primary" onClick={onAccept}>
//           Accept
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default Popup;
