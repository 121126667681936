import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Navbar, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import Logo from "../../assets/logo-sbl.svg";
import { Button, Badge } from "@mui/material";
import "./Navbar.scss";
import Docs from "../../assets/Xario deck.pdf";

function CustomNavbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    // <Navbar className="bg-body-tertiary bg-transparent">
    <Navbar className=" bg-transparent">
      <Container>
        <Navbar.Brand>
          <NavLink
            to={"/"}
            className=" d-flex align-items-center text-decoration-none text-white"
          >
            <Image src={Logo} width={72} />
            {/* <Image src={Logo} width={128} className=" d-block d-md-none" /> */}

            {/* <h2>Xario AI</h2> */}
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar className="justify-content-end d-none d-lg-block">
          <Navbar.Text className="text-capitalize me-3 me-md-5">
            <Link
              to="Pricing"
              spy={true}
              smooth={true}
              duration={500}
              className="text-decoration-none fs-4 text-white"
            >
              Pricing
            </Link>
          </Navbar.Text>
          <Navbar.Text className="text-capitalize me-3 me-md-5">
            <NavLink
              to={Docs}
              // without
              rel="noopener noreferrer"
              target="_blank"
              className=" text-decoration-none fs-4 text-white"
            >
              Docs
            </NavLink>
          </Navbar.Text>
          <Badge badgeContent={"Beta"} color="secondary" size="large">
            <Button
              variant="outlined"
              className="text-capitalize Navbar-chatnow"
            >
              <NavLink
                to={"/chat-now"}
                className=" text-decoration-none fs-4 text-white"
              >
                Try Xario
              </NavLink>
            </Button>
          </Badge>
        </Navbar>
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
            className=" d-lg-none d-block"
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
            className=" d-lg-none d-block"
          />
        )}
        {toggleMenu && (
          <Navbar.Collapse className="Navbar-mb-menu ">
            <Navbar.Text className="text-capitalize me-5">
              <Link
                to="Pricing"
                spy={true}
                smooth={true}
                duration={500}
                className="text-decoration-none fs-4 text-white"
              >
                Pricing
              </Link>
            </Navbar.Text>
            <Navbar.Text className="text-capitalize me-5">
              <NavLink
                to={Docs}
                className=" text-decoration-none fs-4 text-white"
                onClick={() => setToggleMenu(false)}
              >
                Docs
              </NavLink>
            </Navbar.Text>
            <Badge badgeContent={"Beta"} color="secondary" size="large">
              <Button
                variant="outlined"
                className="text-capitalize Navbar-chatnow"
              >
                <NavLink
                  to={"/chat-now"}
                  className=" text-decoration-none fs-4 text-white"
                  onClick={() => setToggleMenu(false)}
                >
                  Try Xario
                </NavLink>
              </Button>
            </Badge>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
