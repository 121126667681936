import React from "react";
import CustomNavbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";

const Layout = ({ children }) => {
  return (
    <>
      <CustomNavbar />
      {children}
      <Footer/>
    </>
  );
};

export default Layout;
