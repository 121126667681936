import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "react-bootstrap";
import logoBlinker from "./assets/Xario-blinker4.gif";
import "./App.scss";
import XarioAI from "./Component/Xario/index";
import Popup from "./Component/Popup/Popup";
import Home from "./Component/Home/Home";


function App() {
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setPopupOpen(true);

    fetchData();
  }, []);

  const handleAccept = () => {
    console.log("User Accept");
    setPopupOpen(false);
  };

  const handleManage = () => {
    console.log("User Manages Cookies");
    setPopupOpen(true);
  };

  return (
    <>
      {loading ? (
        // Display your loader component or message while loading
        <div className="loader-screen">
          <div className="loaderx d-flex justify-content-center">
            <Image
              className=" align-content-between"
              src={logoBlinker}
              width={256}
              height={256}
            />
          </div>
        </div>
      ) : (
        // Your actual application content goes here
        <>
          <Routes>
            <Route exact index element={<Home />} />
            <Route exact path="/chat-now" element={<XarioAI />} />
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="contact" element={<Contact />} />
                <Route path="*" element={<NoPage />} /> */}
          </Routes>
          <Popup
            isOpen={isPopupOpen}
            onAccept={handleAccept}
            onManages={handleManage}
          />
          {/* <div className="loader ">
            <div className="spinner"></div>
            <Image className=" logo" src={logo} />
          </div> */}
        </>
      )}
    </>
  );
}

export default App;
