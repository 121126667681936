import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const ChatNow = ({ PgLink, Tittle }) => {
  return (
    <>
      <Link to={PgLink} className="col-12 col-md-6 mb-4 mb-lg-0  ">
        <Button
          variant="outlined"
          className="w-100 p-3 fs-4 text-white text-capitalize Button-border"
        >
          {Tittle}
        </Button>
      </Link>
    </>
  );
};

export default ChatNow;
