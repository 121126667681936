import React from "react";
import "./Service.scss";
import ImagesBox from "../ImgBox/Images_Box";
import Services from "../../API/ApiLocal";
// import ChatNow from "../Button/ChatNow";

const Service = () => {
  return (
    <>
      <h1 className=" Servics-title py-5">Xario Can:</h1>
      <div className="row py-4">
        {Services.map((value) => (
          <ImagesBox
            id={value.Id}
            key={value.Id}
            titles={value.Sname}
            details={value.Details}
            image={value.Img}
          />
        ))}
      </div>
      {/* <div className=" row d-flex justify-content-center py-5">
        <ChatNow PgLink="/xario" Tittle="Chat Now" />
      </div> */}
    </>
  );
};

export default Service;
