import React from "react";
import { Image } from "react-bootstrap-v5";
import "./Images_Box.scss";

const ImagesBox = ({ titles, image, details, id }) => {
  return (
    <div className="col-12 col-md-12 col-lg-6  mb-5  " key={id}>
      <div className="row Images-Box mx-3  p-4">
        <div className=" col-2 d-flex justify-content-center align-items-center">
          <Image src={image} alt={image} width={72} />
        </div>
        <div className="col-10 ">
          <h1 className="Img-Box-title ">{titles}</h1>
          <p className=" fs-5 ps-2">{details}</p>
        </div>
      </div>
    </div>
  );
};

export default ImagesBox;
