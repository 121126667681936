import React, { useState } from "react";
import { ContentCopy, CopyAll } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./Copy.scss";

const CopyAndShare = ({ textToCopy, textToShare }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
    } catch (error) {
      console.error("Error copying text to clipboard", error);
    }
  };

  // const handleShare = () => {
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: "Shared Text",
  //         text: textToShare,
  //       })
  //       .then(() => console.log("Successfully shared"))
  //       .catch((error) => console.error("Error sharing:", error));
  //   } else {
  //     console.warn("Web Share API not supported");
  //     // You may want to provide a fallback sharing method for browsers that don't support the Web Share API
  //   }
  // };

  return (
    <>
      {/* <p>{textToCopy}</p> */}
      <Button
        onClick={handleCopy}
        className=" text-white m-1 p-1 p-md-2 position-static copy-d-none d-flex justify-content-start"
      >
        {copySuccess ? <ContentCopy /> : <CopyAll />}
      </Button>
      {/* <button onClick={handleShare}>Share Text</button> */}
    </>
  );
};

export default CopyAndShare;
