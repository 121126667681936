import React from "react";
import { Image } from "react-bootstrap-v5";
import "./header.scss";
import banner from "../../assets/Xario-globe.gif";
import ChatNow from "../Button/ChatNow";
import TwoSideArrow from "../Button/TwoSideArrow";
import HeaderTyping from "../Animaction/Header-typing";

const Header = () => {
  return (
    <>
      <div className="row py-5 d-flex align-items-center">
        <div className="col-12 col-md-6 ">
          {/* <h1 className="header-title-1 mb-2"></h1> */}
          {/* <h1 className="header-title mb-5">
            Meet <spam>Xario</spam> Your Ultimate Crypto Wingman.
          </h1> */}
          <HeaderTyping text={ `Meet Xario Your Ultimate Crypto Wingman.`} />

          <p className=" fs-2 mb-5 mb-md-0">
            Real-time AI Coin Analysis: Unleash the power of cutting-edge
            technology with our AI predictive analysis and technical insights.
          </p>
        </div>
        <div className=" col-12 col-md-6">
          <Image src={banner} alt="Xario Ai" className=" img-fluid" />
        </div>
      </div>
      <div className="row py-5">
        <ChatNow PgLink="/chat-now" Tittle="ENTER XARIO" />
        <TwoSideArrow PgLink="/" Tittle="Signup" />
        {/* <TwoSideArrow PgLink="/" Tittle="Xario for Investors" /> */}
      </div>
    </>
  );
};

export default Header;
