// Typewriter.js
import React, { useState, useEffect } from "react";
import "./Typewriter.scss";

const Typewriter = ({ text }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex === text.length) {
        clearInterval(interval);
      } else {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, 30); // Adjust the speed of typing animation here (milliseconds)
    return () => clearInterval(interval);
  }, [currentIndex, text]);

  return <span className="typewriter">{displayText}</span>;
};

export default Typewriter;
