import React from "react";
import "./Pricing.scss"
import Plans from "../Plans/Plans";
// import ChatNow from "../Button/ChatNow";

const Pricing = () => {
  return (
    <section className=" py-5 mb-3" id="Pricing">
      <h1 className="Pricing-title d-flex ps-4 py-3 mb-5 justify-content-center">
        Pricing Plan
      </h1>
      <>
        <Plans />
      </>
      {/* <div className=" row d-flex justify-content-center py-5">
        <ChatNow PgLink="/" Tittle="Xario For Investors" />
      </div> */}
    </section>
  );
};

export default Pricing;
