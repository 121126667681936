import React from "react";
import "./Plans.scss";
import { Button } from "@mui/material";
import { Done, ArrowForward, Block } from "@mui/icons-material";

const Plans = () => {
  return (
    <div className="row">
      <div className=" col-12 col-md-6 col-lg-4 my-4 ">
        <div className=" Plans-Prices p-5 pb-4 mx-5">
          <h2 className=" fs-1 mt-3">
            <b>Standard</b>
          </h2>
          <h1 className="  mb-4 Plans-Titles_prices">
            <b>FREE</b>
          </h1>
          <p className=" fs-4 my-2 mb-5">foreever free for individuals</p>
          <ul className=" Plans_lists py-2">
            <li className=" fs-5 py-2">
              <Done className=" me-2" />
              30 credits
            </li>
            <li className=" fs-5 py-2">
              <Done className=" me-2" />
              10 credits/coin
            </li>
            <li className=" fs-5 py-2">
              <Done className=" me-2" />
              10 credits daily renewal
            </li>
            <li className=" fs-5 py-2">
              <Done className=" me-2" />
              Limited AI Analysis
            </li>
          </ul>
          <div
            className=" d-flex justify-content-center"
            style={{ marginTop: "5.99rem" }}
          >
            <Button
              variant="contained"
              className=" bg-white text-black plan-Button w-75 d-flex justify-content-between"
            >
              <p className="mb-0 d-flex align-items-center fs-5">subscribe</p>
              <ArrowForward className="plan-Button_arrow p-2" />
            </Button>
          </div>
        </div>
      </div>
      <div className=" col-12 col-md-6 col-lg-4 my-4">
        <div className=" Plans-Prices p-5 pb-4 mx-5">
          <h2 className=" fs-1 mt-3">
            <b>Plus</b>
          </h2>
          <h1 className="  mb-4 Plans-Titles_prices">
            <b>$4.99</b>
          </h1>
          <p className=" fs-4">
            Per user/monthly billed $4.99 yearly <b>$55</b> cancel anytime
          </p>
          <ul className=" Plans_lists py-2">
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              500 credits
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              50 crypto analysis
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Priority support
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Free access to discord
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Specific AI price analysis
            </li>
          </ul>
          <div
            className=" d-flex justify-content-center "
            style={{ marginTop: "5.3rem" }}
          >
            <Button
              variant="contained"
              className=" bg-white text-black plan-Button w-75 d-flex justify-content-between"
            >
              <p className="mb-0 d-flex align-items-center fs-5">Get Plus</p>
              <ArrowForward className="plan-Button_arrow p-2" />
            </Button>
          </div>
        </div>
      </div>
      <div className=" col-12 col-md-6 col-lg-4 my-4">
        <div className=" Plans-Prices p-5 pt-2 pb-4 mx-5">
          <div className=" d-flex justify-content-center mb-3">
            <h3>Launching Soon</h3>
          </div>
          <h2 className=" fs-1">
            <b>Premium</b>
          </h2>
          <h1 className="  mb-4 Plans-Titles_prices">
            <b>$19.00</b>
          </h1>
          <p className=" fs-4 mb-0">
            Per user/month billed $19.99 yearly <b>$190</b> cancel anytime
          </p>
          <ul className=" Plans_lists py-2">
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Unlimited credits
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Full access to generative aI
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Unlimited crypto analysis
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Early access to new features
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Specific AI price analysis
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Multimodal- speech to text
            </li>
            <li className=" fs-5 py-1">
              <Done className=" me-2" />
              Generative AI crypto graphs
            </li>
          </ul>
          <div className=" d-flex justify-content-center mt-4 ">
            <Button
              variant="contained"
              disabled
              className=" bg-white text-black plan-Button w-75 d-flex justify-content-between"
            >
              <p className="mb-0 d-flex align-items-center fs-5">Get premium</p>
              {/* <ArrowForward className="plan-Button_arrow p-2" /> */}
              <Block className="plan-Button_arrow p-2" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
