import React from "react";
import Header from "../Header/Header";
import Service from "../Services/Service";
import Pricing from "../Pricing/Pricing";
import Layout from "../../Layout";

const Home = () => {
  return (
    <>
      <Layout>
        <div className=" container">
          <Header />
          <Service />
          <Pricing />
        </div>
      </Layout>
    </>
  );
};

export default Home;
