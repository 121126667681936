import axios from "axios";

// Create an Axios instance for centralized configuration
const axiosInstance = axios.create({
  baseURL: "https://xarioapi.onrender.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// General function to handle GET/POST requests
export async function fetchData(url, method = "GET", data = null) {
  try {
    const response = await axiosInstance({
      method: method,
      url: url,
      data: method === "POST" ? data : null,
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}


// export async function fetchData(url) {
//   try {
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// }

// import axios from "axios";

// export async function fetchData(url) {
//   try {
//     const response = await axios.get(url);

//     // Axios handles non-2xx responses differently
//     // You may choose to customize this part based on your requirements
//     if (response.status !== 200) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// }
